import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faLinkedin, faTwitter, faFacebook  } from "@fortawesome/free-brands-svg-icons"
import { faAt } from "@fortawesome/free-solid-svg-icons"

import Navigation from "./navigation"

const avatar = require('../assets/images/avatar.jpg');

export default () => (
    <header>
        <div className="site-name-container">
            <img src={avatar} alt="Avatar" className="avatar" />
            <Link to="/">
                <h3 className="site-name-link">peteromalley.co.uk</h3>
            </Link>
        </div>
        <div className="social-links-div">
            <div className="social-links-sub-div">
                <span className="social-links-span">
                    <a href="https://www.linkedin.com/in/peter-o-malley-b43847164" title="LinkedIn" alt="Linkedin" target="_blank" rel="noopener noreferrer" className="social-links">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                </span>
                <span className="social-links-span">
                    <a href="https://github.com/pomalley83" title="Github" alt="Github" target="_blank" rel="noopener noreferrer" className="social-links">
                        <FontAwesomeIcon icon={faGithub} />
                    </a>
                </span>
                <span className="social-links-span">
                    <a href="https://twitter.com/peter_omalley" title="Twitter" alt="Twitter" target="_blank" rel="noopener noreferrer" className="social-links">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                </span>
                <span className="social-links-span">
                    <a href="https://facebook.com/peter.omalley.94" title="Facebook" alt="Facebook" target="_blank" rel="noopener noreferrer" className="social-links">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                </span>
                <span className="social-links-span">
                    <a href="mailto:pomalley83@gmail.com" title="Email" alt="Email" target="_blank" rel="noopener noreferrer" className="social-links">
                        <FontAwesomeIcon icon={faAt} />
                    </a>
                </span>
            </div>
            <div className="social-links-sub-div">
                <Navigation />
            </div>
        </div>
        
    </header>
)