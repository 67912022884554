import React from 'react'
import Helmet from 'react-helmet'

export default () => (
    <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>peteromalley.co.uk</title>
        <meta name="title" content="peteromalley.co.uk" />
        <meta name="description" content="My personal website about Peter O'Malley, a PHP and JavaScript developer based in Lisburn, Northern Ireland." />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.peteromalley.co.uk/" />
        <meta property="og:title" content="peteromalley.co.uk" />
        <meta property="og:description" content="My personal website about Peter O'Malley, a PHP and JavaScript developer based in Lisburn, Northern Ireland." />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.peteromalley.co.uk/" />
        <meta property="twitter:title" content="peteromalley.co.uk" />
        <meta property="twitter:description" content="My personal website about Peter O'Malley, a PHP and JavaScript developer based in Lisburn, Northern Ireland." />
    
         {/* <!-- Favicomatic --> */}
        <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16" />
    </Helmet>
)