import React from "react"
import Layout from "../components/layout"

export default () => (
    <Layout>
        <h1 className="page-title">My experience</h1>
        <div className="experience-block">
            <strong>April 2015 - Present</strong>
            <br />
            <div>
                <span className="job-title">Support Team Lead,</span> Tascomi Ltd
                <div className="job-content">
                    In April 2015 I was promoted to the role of Team Lead for the Support Team. The team is currently made up of six developers all of which have come in as graduates. My main duties in this role include managing the team, ensuring they are carrying out their daily duties as well as answering any questions they may have about support tickets that have come in or about the systems themselves.
                </div>
                <div className="job-content">
                    Having come into Tascomi as a graduate it is a role that I have enjoyed and got a lot of pleasure from in seeing the graduates come in and develop their own skillsets from the guidance and coaching I have given them.
                </div>               
            </div>
        </div>

        <div className="experience-block">
            <strong>May 2012 - Present</strong>
            <br />
            <div>
                <span className="job-title">Senior Software Developer,</span> Tascomi Ltd
                <div className="job-content">
                    After four years I progressed to the role of Senior Software Developer. My main responsibilities are maintaining the codebase and the coding standards of our current systems whilst also working on implementing new products. The main product I have been responsible for is the company’s online portal solution, Council Direct; that allows users to register and pay for or request a range of services from their local authority. Council Direct integrates seamlessly with the back-office systems we offer.
                </div>
                <div className="job-content">
                    I have also implemented a nationwide portal for the Health Service Executive in the Republic of Ireland to allow a variety of services to be applied for online. These include allowing owners of premises with sunbed facilities to register them online and allowing food businesses to apply for export certificates online to allow their products to be shipped all over the world.
                </div>
            </div>
        </div>

        <div className="experience-block">
            <strong>April 2008 - May 2012</strong>
            <br />
            <div>
                <span className="job-title">Junior Software Developer,</span> Tascomi Ltd
                <div className="job-content">
                    When I began at Tascomi I was primarily involved within the support side of the business. This involved taking calls from customers, understanding the issue they were having, carrying out fixes for these issues and informing customers when the fixes were deployed to their live systems.
                </div>
                <div className="job-content">
                    I was also involved in developing some smaller improvements and modules in the systems to help grow my skills.
                </div>
            </div>
        </div>	

        <div className="skills-block">
            Skills:<span className="skills-list">PHP, Postgres, HTML, CSS, JavaScript, jQuery, Smarty, GIT, Agile</span>
        </div>

        <div className="experience-block">
            <strong>September 2007 - April 2008</strong>
            <br />
            <div>
                <span className="job-title">Quality Assurance Engineer,</span> Mobile Cohesion
                <div className="job-content">
                This was a continuation of the role that I carried out during my year of professional experience during my degree course. I was responsible for both manual and automated (using Selenium) testing of the company’s product Hydra. My main duties were logging bugs and carrying out regression testing prior to builds.
                </div>
                <div className="job-content">
                    Mobile Cohesion stopped operating in April 2008.
                </div>
            </div>
        </div>	

        <div className="skills-block">
            Skills:<span className="skills-list">Manual Testing, Selenium</span>
        </div>
    </Layout>
)