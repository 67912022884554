import React from "react"
import Header from "./header"
import Footer from "./footer"

import Head from "./head"

import "../assets/bootstrap.min.css"
import "../assets/main.css"

export default ({ children }) => (
    <div style={{ margin: `3rem auto`, maxWidth: 700, padding: `0 1rem`, minHeight: `100%`, marginBottom: `-50px` }}>
        <Head />
        <Header />
        {children}
        <Footer />
    </div>
)